import React, { useState, useEffect } from 'react';
import cat1 from './pics/1.jpg'
import cat2 from './pics/2.jpg'
import rip from './pics/rip-juice-cry.gif'
import cry from './pics/cry.jpg'
import cry2 from './pics/cry-crying.gif'
import beg from './pics/beg.jpg'
import beg2 from './pics/beg2.jpg'
import grumpy from './pics/grumpy.jpg'
import sad from './pics/sad-sad-cat.gif'
import ilu from './pics/hug.gif'
import annoyed from './pics/annoyed-disappointed.gif'
import './App.css';
import Button from 'react-bootstrap/Button'
import Confetti from 'react-confetti'

function App() {
  // State variable to track button size
  const [imageSrc, setImageSrc] = useState(cat1);
  const [topLine, setTopLineText] = useState("Hai darling!!!")
  const [bottomLine, setBottomLineText] = useState("Will you be my valentine?")
  const [confetti, setConfetti] = useState(false)
  const [darknessLevel, setDarknessLevel] = useState(0);
  const [buttonVisible, setButtonVisibility] = useState(true)
  const [buttonPressCount, setButtonPressCount] = useState(0);
  const [noButtonVariant, setNoButtonVariant] = useState("secondary");
  const [noButtonText, setNoButtonText] = useState("No");
  const [extraYesButton, setExtraYesButton] = useState(false)
  const [allExtraYesButtons, setAllExtraYesButtons] = useState(false)
  const [yesSize, setYesSize] = useState(60)
  const [noSize, setNoSize] = useState(60)
  const [deltaX, setDeltaX] = useState(40);
  const [deltaY, setDeltaY] = useState(40);
  const [extraYesButtons, setExtraYesButtons] = useState([]);
  const [noButtonStyle, setNoButtonStyle] = useState({ 
    "font-size": `${noSize}px`,
    "height": `${1.5 * noSize}px`,
    "width": `${3.5 * noSize}px`,
    "padding": '10px',
    "textAlign": "center",
    "display": 'flex',
    "justifyContent": 'center', // Horizontally center the text
    "alignItems": 'center', // Vertically center the text
    "border-radius": '1000px',
  });
  const [yesButtonStyle, setYesButtonStyle] = useState({ 
    "font-size": `${yesSize}px`,
    "height": `${1.5 * yesSize}px`,
    "width": `${3.5 * yesSize}px`,
    "padding": '10px',
    "textAlign": "center",
    "display": 'flex',
    "justifyContent": 'center', // Horizontally center the text
    "alignItems": 'center', // Vertically center the text
    "margin-right": '10px',
    "border-radius": '1000px', 
  });


  // Function for handling 'Yes' button click
  const sheSaidYes = () => {
    // Logic for 'Yes' button click
    console.log("She said Yes!");
    setTopLineText("YAAAAAAYYYYYY LES GOOOOOO")
    setBottomLineText("hehehe love u bby")
    setImageSrc(ilu)
    setButtonVisibility(false)
    setConfetti(true);
    setDarknessLevel(0.0);
    setExtraYesButton(false)
    setAllExtraYesButtons(false)
  }

  const sheSaidNo = () => {
    setTopLineText("")
    if (buttonPressCount > 8)
    {
      setImageSrc(rip)
      setDarknessLevel(level => level + 0.2);
      setButtonPressCount(count => count + 1)
      setDeltaX(deltaX => deltaX * 1.5)
      setDeltaY(deltaY => deltaY * 1.5)
    }

    setYesSize(size => size + 5)
    setNoSize(size => size  - 5)


    switch (buttonPressCount) {
      case 0:
        setNoButtonVariant("danger")
        setBottomLineText("Will you be my valentine, please?")
        setImageSrc(cat2)
        break;
      case 1:
        setNoButtonStyle({    
          "font-size": `${noSize}px`,
          "height": `${1.5 * noSize}px`,
          "width": `${3.5 * noSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "border-radius": '1000px',
        })
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setBottomLineText("darling?")
        setImageSrc(grumpy)
        break;
      case 2:
        setBottomLineText("...")
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center', 
          "alignItems": 'center', 
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setNoButtonText("oN")
        setImageSrc(annoyed)
        break;
      case 3:
        setNoButtonText("No")
        setBottomLineText("cmon plz")
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center', 
          "alignItems": 'center', 
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setNoButtonStyle({
          "font-size": `${noSize}px`,
          "height": `${1.5 * noSize}px`,
          "width": `${3.5 * noSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "border-radius": '1000px',
        })
        setExtraYesButton(true)
        setImageSrc(beg)
        break;
      case 4:
        setBottomLineText("dun do this to me")
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center', 
          "alignItems": 'center', 
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setNoButtonStyle({
          "font-size": `${noSize}px`,
          "height": `${1.5 * noSize}px`,
          "width": `${3.5 * noSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "border-radius": '1000px',
          "position": "absolute",
          "bottom": "50%",
          "right": `${(3.5 * noSize) + 10}px`
        })
        setImageSrc(cry)
        break;
      case 5:
        setBottomLineText("plz bby")
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center', 
          "alignItems": 'center', 
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setExtraYesButton(false)
        setNoButtonStyle({
          "font-size": `${noSize}px`,
          "height": `${1.5 * noSize}px`,
          "width": `${3.5 * noSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "border-radius": '1000px',
          "position": "absolute",
          "bottom": `${(1.5 * noSize) + 10}px`,
          "right": `${(3.5 * noSize) + 10}px`,
          "transform": "translate(50%, 50%)"
        })
        setImageSrc(sad)
        break;
      case 6:
        setBottomLineText("i'm beggin u")
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center', 
          "alignItems": 'center', 
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setNoButtonVariant("transparent")
        setNoButtonStyle({
          "font-size": `${noSize}px`,
          "height": `${1.5 * noSize}px`,
          "width": `${3.5 * noSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "border-radius": '1000px',
          "position": "absolute",
          "top": `${(1.5 * noSize) + 10}px`,
          "left": `${(3.5 * noSize) + 10}px`,
          "transform": "translate(50%, 50%)"
        })
        setImageSrc(beg2)
        break;
      case 7:
        setAllExtraYesButtons(true)
        setNoButtonVariant("danger")
        setBottomLineText("no but fr tho")
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center', 
          "alignItems": 'center', 
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setNoButtonStyle({
          "font-size": `${noSize}px`,
          "height": `${1.5 * noSize}px`,
          "width": `${3.5 * noSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "border-radius": '1000px',
          "position": 'absolute',
          "top": "0px",
          "left": "0px",
          "transition": 'all 0.2s ease-in-out',
        })
        setImageSrc(cry)
        break;
      case 8:
        setBottomLineText("you give me no choice...")
        setYesButtonStyle({    
          "font-size": `${yesSize}px`,
          "height": `${1.5 * yesSize}px`,
          "width": `${3.5 * yesSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center', 
          "margin-right": '10px',
          "border-radius": '1000px'
          })
        setNoButtonStyle({
          "font-size": `${noSize}px`,
          "height": `${1.5 * noSize}px`,
          "width": `${3.5 * noSize}px`,
          "padding": '10px',
          "textAlign": "center",
          "display": 'flex',
          "justifyContent": 'center',
          "alignItems": 'center',
          "border-radius": '1000px',
          "position": 'absolute',
          "top": "0px",
          "left": "0px",
          "transition": 'all 0.2s ease-in-out',
        })
        setImageSrc(cry2)
        setDeltaX(deltaX => 150)
        setDeltaY(deltaY => 150)
        break;
      default:
        break;
    }
    

    setButtonPressCount(count => count + 1)
  }

  useEffect(() => {
    if (buttonPressCount >= 7) {
      // Generate random positions for extra "Yes" buttons
      const buttons = [];
      let numButtons = buttonPressCount * 3

      for (let i = 0; i < numButtons; i++) {
          let top = Math.random() * (window.innerHeight - 90);
          let left = Math.random() * (window.innerWidth - 210);
        buttons.push({ top, left });
      }
      setExtraYesButtons(buttons);
    }
    if (buttonPressCount >= 8) {
      const handleAnimation = () => {
        const button = document.getElementById('moveableButton');
        if (!button) return;
        const buttonRect = button.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
  
        // Calculate new positions
        let newX = buttonRect.left + deltaX;
        let newY = buttonRect.top + deltaY;
  
        // Reverse direction if hitting the edge
        if (newX <= 0 || newX >= viewportWidth - buttonRect.width) {
          setDeltaX(deltaX => -deltaX);
        }
        if (newY <= 0 || newY >= viewportHeight - buttonRect.height) {
          setDeltaY(deltaY => -deltaY);
        }
  
        // Update button position
        newX = Math.max(0, Math.min(newX, viewportWidth));
        newY = Math.max(0, Math.min(newY, viewportHeight));
        button.style.left = `${newX}px`;
        button.style.top = `${newY}px`;
      };
  
      const animationInterval = setInterval(handleAnimation, 30);
      return () => clearInterval(animationInterval);
    }
  }, [buttonPressCount, deltaX, deltaY]);
  
  
  

  const overlayBackgroundColor = `rgba(0, 0, 0, ${darknessLevel})`

  return (
    <div className="App">
      <header className="App-header">
      {allExtraYesButtons && extraYesButtons.map((button, index) => (
          <Button key={index} variant="primary" style={{ position: 'absolute', top: button.top, left: button.left }} onClick={sheSaidYes}>Yes</Button>
        ))}
      <div className="overlay" style={{ backgroundColor: overlayBackgroundColor }}></div>
        {topLine}<br/>
        <img src={imageSrc} id="picture" className="cat non-selectable" alt="logo" />
        <p id="bottomLine" className='non-selectable'>
          {bottomLine}
        </p>
        {buttonVisible && (
          <div style={{"display":"flex","justifyContent":"space-between","alignItems":"center","padding":"10px"}}>
            <Button variant='primary' id="mainYes" className='butt' onClick={sheSaidYes} style={yesButtonStyle}>Yes</Button>{' '}
            {extraYesButton && (
              <Button variant='primary' className='butt' onClick={sheSaidYes} style={yesButtonStyle}>Yes</Button>
            )}
            <Button id="moveableButton" variant={noButtonVariant} className='butt' onClick={sheSaidNo} style={noButtonStyle}>{noButtonText}</Button>
          </div>
        )}
        {confetti && <Confetti />}
      </header>
    </div>
  );
}

export default App;
